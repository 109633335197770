import React, { useEffect, useState } from 'react';
import { Alert } from '@grafana/ui';
import { PluginPage } from '@grafana/runtime';
import { DashboardCard } from 'components/DashboardCard';
import { checkDatasourcesExists, checkFolderExists, removeDatasources, runMigrations } from 'lib/backend';
import { dashboards, demoInfraSources } from 'data/data.dashboard';
import { DemoInfraDashboardVariant, VerifiedDashboard } from 'types/types.dashboard';

import { faro } from '../module';
import useRudderStack from 'lib/rudderstack';

export function RootPage() {
  const rs = useRudderStack();

  const [dataSourceOrphaned, setDataSourceOrphaned] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [verifiedDashboards, setVerifiedDashboards] = useState<VerifiedDashboard[]>([]);

  useEffect(() => {
    faro.unpause();
    return faro.pause;
  }, []);
  useEffect(() => {
    runMigrations();
  });
  const refresh = (e: React.MouseEvent) => {
    setRefreshKey(e.timeStamp);
  };
  useEffect(() => {
    (async () => {
      const existsDatasource = await Promise.all(demoInfraSources.map(checkDatasourcesExists));
      const hasDatasource = existsDatasource.some((source) => source);

      const existsDashboards: boolean[] = [];
      const dashboardArray: VerifiedDashboard[] = [];

      await Promise.all(
        dashboards.map(async (dashboard) => {
          if (dashboard.checkFolder) {
            const checkFolderResp = await checkFolderExists(dashboard.variant);
            existsDashboards.push(checkFolderResp);

            // unshift puts weather dashboard as first since it's the most popular one
            if (dashboard.key === DemoInfraDashboardVariant.Weather) {
              dashboardArray.unshift({ ...dashboard, isInstalled: checkFolderResp });
            } else {
              dashboardArray.push({ ...dashboard, isInstalled: checkFolderResp });
            }
          } else {
            dashboardArray.push({ ...dashboard, isInstalled: hasDatasource });
          }
        })
      );

      setVerifiedDashboards([...dashboardArray]);

      // first condition checks if there's any truthy value
      // second condition returns true if none in the array returns truthy value
      if (hasDatasource && !existsDashboards.some((x) => x)) {
        setDataSourceOrphaned(true);
      }
    })();
  }, [refreshKey]);
  return (
    <PluginPage>
      <div>
        <Alert severity="info" title="What happens when I click install?">
          Clicking on install will add datasources and dashboards to your current instance. These dashboards will not
          overwrite any existing dashboards. You can then explore these datasources and dashboards to see how you can
          accomplish similar goals. You will <strong>not</strong> be billed for this example data.
        </Alert>
        {dataSourceOrphaned && (
          <Alert
            severity="warning"
            title="You have removed all dashboards but the demo-datasources are still present"
            buttonContent={'Clean up datasources'}
            onRemove={() => {
              removeDatasources();
              rs.trackRudderStackEvent('grafana_plugin_demo_dashboards_datasource_removed', {});

              setDataSourceOrphaned(false);
            }}
          >
            This might be intentional if you are only using the data sources.
          </Alert>
        )}
      </div>
      {!!verifiedDashboards.length &&
        verifiedDashboards.map((dashboard, i) => {
          return (
            <DashboardCard
              key={`${dashboard.key}-${i}`}
              onUninstall={refresh}
              maskDirection={i % 2 ? 'right' : 'left'}
              dashboard={dashboard}
            />
          );
        })}
    </PluginPage>
  );
}

import pluginJson from './plugin.json';
import { config } from '@grafana/runtime';

export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;

// Faro settings
const FARO_DEV_ENDPOINT =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/c7c55f3af556d778d8ba155f43cce840';
const FARO_OPS_ENDPOINT =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/711c8651bbd07b8a135f95d997bf75c2';
const FARO_PROD_ENDPOINT =
  'https://faro-collector-prod-us-central-0.grafana.net/collect/30fae4787d1d8d037d9323c189e93cf0';
const appUrl = config.appUrl;
const FARO_APP_DEV = 'grafana-demodashboards-dev';
const FARO_APP_OPS = 'grafana-demodashboards-ops';
const FARO_APP_PROD = 'grafana-demodashboards-prod';

export const FARO_ENDPOINT = (() => {
  if (appUrl.includes('grafana-dev.net') || appUrl.includes('localhost')) {
    return FARO_DEV_ENDPOINT;
  }
  if (appUrl.includes('grafana-ops.net')) {
    return FARO_OPS_ENDPOINT;
  }
  return FARO_PROD_ENDPOINT;
})();

export const FARO_APP_NAME = (() => {
  if (appUrl.includes('grafana-dev.net') || appUrl.includes('localhost')) {
    return FARO_APP_DEV;
  }
  if (appUrl.includes('grafana-ops.net')) {
    return FARO_APP_OPS;
  }
  return FARO_APP_PROD;
})();

import { AppPlugin } from '@grafana/data';
import { config } from '@grafana/runtime';
import { FARO_APP_NAME, FARO_ENDPOINT } from './constants';
import { App } from './components/App';
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import pluginJson from './plugin.json';

export const faro = initializeFaro({
  url: FARO_ENDPOINT,
  app: {
    name: FARO_APP_NAME,
    version: config.apps[pluginJson.id].version,
    environment: process.env.NODE_ENV,
  },
  isolate: true,
  user: {
    id: config.bootData.user.orgName,
  },
  instrumentations: [...getWebInstrumentations()],
});

export const plugin = new AppPlugin<{}>().setRootPage(App);

import { AppContextData } from 'components/App';

export enum DemoInfraDashboardVariant { // our own data stored
  QuickPizza = 'quickpizza',
  Weather = 'weather',
}

export enum GrafanaDashboardVariant {
  K8 = 'k8',
  AppO11y = 'appO11y',
  Logs = 'logs',
}

export type DashboardVariant = DemoInfraDashboardVariant;

export interface Dashboard {
  // the datasource dashboard relies on
  variant: DashboardVariant;
  // dashboard key (we use this for testing)
  key: DemoInfraDashboardVariant | GrafanaDashboardVariant;
  // the dashboard screenshot
  screenshot: string;
  // title of demo dashboard
  title: string;
  // description of demo dashboard
  description: string;
  // bullet points of features
  features: string[];
  // where users get redirected to when view dashboard
  redirectUrl: string | ((ctx: AppContextData) => string);
  // indicates whether dashboard requires an api call to /api/folders
  checkFolder?: boolean;
}

export interface VerifiedDashboard extends Dashboard {
  isInstalled?: boolean;
}

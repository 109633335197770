import { AppContext } from 'components/App';
import { useCallback, useContext } from 'react';

const useRudderStack = () => {
  const { stackId, orgId } = useContext(AppContext);

  const trackRudderStackEvent = useCallback(
    async (event: string, params: object) => {
      return await new Promise<void>((resolve) => {
        (window as any).rudderanalytics?.track(
          event,
          {
            ...params,
            org_id: orgId,
            stack_id: stackId,
          },
          null,
          (data: any) => {
            console.log(`resolving ${JSON.stringify(data)}`);
            resolve();
          }
        );
      });
    },
    [orgId, stackId]
  );

  return {
    trackRudderStackEvent,
  };
};

export default useRudderStack;

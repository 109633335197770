import { demoInfraSources } from 'data/data.dashboard';
import { DemoInfraDashboardVariant } from 'types/types.dashboard';

const PLUGIN_API_ROOT = '/api/plugins/grafana-demodashboards-app';
export const installDashboards = async function(variant: DemoInfraDashboardVariant) {
  return fetch(`${PLUGIN_API_ROOT}/resources/dashboards`, {
    method: 'POST',
    body: JSON.stringify({ variant: variant }),
  });
};

export const uninstallDashboards = async function(variant: DemoInfraDashboardVariant) {
  return fetch(`${PLUGIN_API_ROOT}/resources/dashboards`, {
    method: 'DELETE',
    body: JSON.stringify({ variant: variant }),
  });
};

export const installDatasources = async function() {
  return fetch(`${PLUGIN_API_ROOT}/resources/datasources`, { method: 'POST' });
};
export const runMigrations = async function() {
  return fetch(`${PLUGIN_API_ROOT}/resources/migrate`, { method: 'POST' });
};

const FolderUIDs: Record<DemoInfraDashboardVariant, string> = {
  [DemoInfraDashboardVariant.QuickPizza]: 'grafana-demodashboards-quickpizza',
  [DemoInfraDashboardVariant.Weather]: 'grafana-demodashboards-weather',
};

export const checkFolderExists = async function(variant: DemoInfraDashboardVariant) {
  const canaryUid = FolderUIDs[variant];
  const resp = await fetch(`/api/folders/${canaryUid}`);
  return resp.status === 200;
};

export const checkDatasourcesExists = async function(uid: string) {
  const resp = await fetch(`/api/datasources/uid/${uid}`);
  return resp.status === 200;
};

export const removeDatasources = async function() {
  for (const uid of demoInfraSources) {
    await fetch(`/api/datasources/uid/${uid}`, { method: 'DELETE' });
  }
  // refresh page to ensure GrafanaDashboard variants does not have datasource connected anymore
  window.location.reload();
};

import React, { createContext } from 'react';
import { AppRootProps } from '@grafana/data';
import { RootPage } from '../../pages';

export interface AppContextData {
  stackId: number;
  orgId: number;
  region: string;
}

export const AppContext = createContext<AppContextData>({
  stackId: -1,
  orgId: -1,
  region: '',
});

export const App = React.memo(function App(props: AppRootProps) {
  const stackId = props.meta?.jsonData?.['stackId'] ?? -1;
  const orgId = props.meta?.jsonData?.['orgId'] ?? -1;
  const region = props.meta?.jsonData?.['region'] ?? '';

  return (
    <AppContext.Provider value={{ stackId, orgId, region }}>
      <RootPage />
    </AppContext.Provider>
  );
});
